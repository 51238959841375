define("ember-yeti-table/components/yeti-table/tfoot/row/cell/component", ["exports", "@ember-decorators/component", "ember-yeti-table/components/yeti-table/tfoot/row/cell/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
    Renders a `<td>` element and yields for the developer to supply content.
  
    ```hbs
    <table.tfoot as |foot|>
      <foot.row as |row|>
        <row.cell>
          Footer content
        </row.cell>
      </foot.row>
    </table.tfoot>
    ```
  
   */
  let TFootCell = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed.reads('column.visible'), _dec(_class = _dec2(_class = (_class2 = class TFootCell extends Ember.Component {
    constructor() {
      super(...arguments);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "parent", void 0);
      /**
       * Controls the visibility of the current cell. Keep in mind that this property
       * won't just hide the column using css. The DOM for the column will be removed.
       * Defaults to the `visible` argument of the corresponding column.
       */
      _initializerDefineProperty(this, "visible", _descriptor, this);
    }
    init() {
      super.init(...arguments);
      if (this.get('parent')) {
        this.get('parent').registerCell(this);
      }
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      if (this.get('parent')) {
        this.get('parent').unregisterCell(this);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "visible", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  var _default = TFootCell;
  _exports.default = _default;
});