define("ember-is-mobile/services/is-mobile", ["exports", "ember-is-mobile"], function (_exports, _emberIsMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
   * The attributes returned by isMobile are accessible. However, they should be
   * accessed using the `get` helper, since they may be undefined if the user
   * agent header is blank.
   */
  class IsMobileService extends Ember.Service {
    get fastboot() {
      return Ember.getOwner(this).lookup('service:fastboot');
    }
    constructor() {
      super(...arguments);
      _defineProperty(this, "tests", {});
      let tests;
      if (this.fastboot !== undefined && this.fastboot.isFastBoot) {
        let headers = this.fastboot.request.headers;
        let userAgent = headers.headers['user-agent'];

        // isMobile tries to fetch `navigator` if we give it a blank user agent.
        if (Ember.isBlank(userAgent)) {
          return;
        }
        tests = (0, _emberIsMobile.default)(userAgent[0]);
      } else {
        tests = (0, _emberIsMobile.default)();
      }
      for (let media in tests) {
        this[media] = tests[media];
      }
      this.tests = tests;
    }
  }
  _exports.default = IsMobileService;
});