define("ember-query-params-service/services/query-params", ["exports", "qs"], function (_exports, qs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let QueryParamsService = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, (_class = class QueryParamsService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "current", _descriptor2, this);
      _initializerDefineProperty(this, "byPath", _descriptor3, this);
      this.setupProxies();
      this.updateParams();

      // TODO: drop support for Ember < 3.24 and use @ember/destroyable
      //       to not cause a memory leak in tests
      this.router.on('routeDidChange', () => {
        this.updateParams();
        this.updateURL(this.router.currentRouteName);
      });
    }
    get pathParts() {
      const [path, params] = (this.router.currentURL || '').split('?');
      const absolutePath = path.startsWith('/') ? path : `/${path}`;
      return [absolutePath, params];
    }
    setupProxies() {
      let [path] = this.pathParts;
      this.byPath[path] = this.byPath[path] || {};
      this.current = new Proxy(this.byPath[path], queryParamHandler);
    }
    updateParams() {
      this.setupProxies();
      const [path, params] = this.pathParts;
      const queryParams = params ? qs.parse(params) : {};
      this.setOnPath(path, queryParams);
    }

    /**
     * When we have stored query params for a route
     * throw them on the URL
     *
     */
    updateURL(routeName) {
      const path = this.router.urlFor(routeName);
      const {
        protocol,
        host,
        pathname,
        search,
        hash
      } = window.location;
      const queryParams = this.byPath[path];
      const existing = qs.parse(search.split('?')[1]);
      const query = qs.stringify(sortKeys({
        ...existing,
        ...queryParams
      }));
      const newUrl = `${protocol}//${host}${pathname}${hash}${Ember.isPresent(query) ? '?' : ''}${query}`;
      window.history.replaceState({
        path: newUrl
      }, '', newUrl);
    }
    setOnPath(path, queryParams) {
      this.byPath[path] = this.byPath[path] || {};
      Object.keys(queryParams || {}).forEach(key => {
        let value = queryParams[key];
        let currentValue = this.byPath[path][key];
        if (currentValue === value) {
          return;
        }
        if (value === undefined) {
          delete this.byPath[path][key];
          return;
        }
        this.byPath[path][key] = value;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "current", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "byPath", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  })), _class));
  _exports.default = QueryParamsService;
  function sortKeys(unordered) {
    return Object.keys(unordered).sort().reduce((obj, key) => {
      obj[key] = unordered[key];
      return obj;
    }, {});
  }
  const queryParamHandler = {
    get(obj, key) {
      for (var _len = arguments.length, rest = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        rest[_key - 2] = arguments[_key];
      }
      return Reflect.get(obj, key, ...rest);
    },
    set(obj, key, value) {
      let {
        protocol,
        host,
        pathname
      } = window.location;
      let query = qs.stringify(sortKeys({
        ...obj,
        [key]: value
      }));
      let newUrl = `${protocol}//${host}${pathname}${Ember.isPresent(query) ? '?' : ''}${query}`;
      window.history.pushState({
        path: newUrl
      }, '', newUrl);
      for (var _len2 = arguments.length, rest = new Array(_len2 > 3 ? _len2 - 3 : 0), _key2 = 3; _key2 < _len2; _key2++) {
        rest[_key2 - 3] = arguments[_key2];
      }
      return Reflect.set(obj, key, value, ...rest);
    }
  };

  // DO NOT DELETE: this is how TypeScript knows how to look up your services.
});