define("ember-yeti-table/components/yeti-table/body/component", ["exports", "@ember-decorators/component", "ember-yeti-table/components/yeti-table/body/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /**
    Renders a `<tbody>` element and yields the row component, row data and index.
    ```hbs
    <table.body as |body person index|>
      <body.row as |row|>
        <row.cell>
          {{person.firstName}} #{{index}}
        </row.cell>
        <row.cell>
          {{person.lastName}}
        </row.cell>
        <row.cell>
          {{person.points}}
        </row.cell>
      </body.row>
    </table.body>
    ```
    It can also be used as a blockless component to let yeti table automatically
    unroll thee rows for you, based on the `@prop` arguments you passed in to the
    column definition components.
    ```hbs
    <table.body/>
    ```
    Remember that this component's block will be rendered once per each item in the `@data` array.
    @yield {object} body
    @yield {Component} body.row - the row component
    @yield {Object} rowData - one item in the data array
    @yield {number} index
  */
  let Body = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class Body extends Ember.Component {
    constructor() {
      super(...arguments);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "data", void 0);
      _defineProperty(this, "columns", void 0);
      _defineProperty(this, "parent", void 0);
      /**
       * Adds a click action to each row, called with the clicked row's data as an argument.
       * Can be used with both the blockless and block invocations.
       */
      _defineProperty(this, "onRowClick", void 0);
    }
    handleRowClick(rowData) {
      this.onRowClick?.(rowData);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "handleRowClick", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleRowClick"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = Body;
  _exports.default = _default;
});