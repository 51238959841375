define("ember-yeti-table/components/yeti-table/tfoot/component", ["exports", "@ember-decorators/component", "ember-yeti-table/components/yeti-table/tfoot/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
    Renders a `<tfoot>` element and yields the row component.
    ```hbs
    <table.tfoot as |foot|>
      <foot.row as |row|>
        <row.cell>
          Footer content
        </row.cell>
      </foot.row>
    </table.tfoot>
    ```
  
    @yield {object} footer
    @yield {Component} footer.row
  */
  let TFoot = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class TFoot extends Ember.Component {
    constructor() {
      super(...arguments);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "parent", void 0);
      _defineProperty(this, "columns", void 0);
    }
    init() {
      super.init(...arguments);
      (true && !(!this.shouldDeprecate) && Ember.deprecate('The yielded `foot` component has been deprecated. Please use `tfoot` instead.', !this.shouldDeprecate, {
        id: 'ember-yeti-table:foot-component',
        until: '2.0.0',
        for: 'ember-yeti-table',
        since: {
          enable: '1.4.0'
        }
      }));
    }
  }) || _class) || _class);
  var _default = TFoot;
  _exports.default = _default;
});