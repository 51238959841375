define("ember-sortable/services/ember-sortable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class EmberSortableService extends Ember.Service {
    constructor() {
      super(...arguments);
      /**
       * Internal State for any groups currently in DOM
       *
       * {
       *   groupName: {
       *     groupModifier: object,
       *     items: []
       *   }
       * }
       * @type {{}}
       */
      _defineProperty(this, "groups", {});
    }
    /**
     * Register a new group with the service
     *
     * @param {String} groupName
     * @param {SortableGroupModifier} groupModifier
     */
    registerGroup(groupName, groupModifier) {
      if (this.groups[groupName] === undefined) {
        this.groups[groupName] = {
          groupModifier: groupModifier,
          items: []
        };
      } else {
        this.groups[groupName].groupModifier = groupModifier;
      }
    }

    /**
     * De-register a group with the service
     *
     * @param {String} groupName
     */
    deregisterGroup(groupName) {
      delete this.groups[groupName];
    }

    /**
     * Register an item with this group
     *
     * @method registerItem
     * @param {String} groupName
     * @param {SortableItemModifier} item
     */
    registerItem(groupName, item) {
      let groupDef = this.fetchGroup(groupName);
      let items = groupDef.items;
      if (items.indexOf(item) === -1) {
        items = [...items, item];
      }
      groupDef.items = items;
    }

    /**
     De-register an item with this group.
      @method deregisterItem
     @param groupName
     @param item
     */
    deregisterItem(groupName, item) {
      let groupDef = this.fetchGroup(groupName);
      let items = groupDef.items;
      const index = items.indexOf(item);
      if (index !== -1) {
        let newItems = [...items.slice(0, index), ...items.slice(index + 1)];
        groupDef.items = newItems;
      }
    }

    /**
     * Fetch a group definition
     *
     * @param {String} groupName
     * @returns {*}
     */
    fetchGroup(groupName) {
      if (this.groups[groupName] === undefined) {
        this.registerGroup(groupName, undefined);
      }
      return this.groups[groupName];
    }
  }
  _exports.default = EmberSortableService;
});